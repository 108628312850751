<template>
<div>
  <img src="../../assets/images/image@2x.png" class="image" alt="">
  <div class="content-text">德润大数据始建于2018年，是一家专注于为广大有资金需求的企业及个人提供金融服务的多元化公司。</div>
  <div class="content-text">在强力的市场竞争和需求下，公司愿以最敏锐的洞察力、最高效的沟通力、最稳健的控制力和最先行的执行力、整合一切可利用资源帮助客户实现金融助贷愿景和目标、与互联网信息化服务全方位接轨。公司以推动大数据整合为己任，致力于传统流程和大数据分析的结合，努力建设成为数据完备 、技术领先、专业、权威的大数据金融企业,助力于小微企业、银行机构、个人助贷的金融服务与合作。</div>
  <div class="content-text">主营业务有房屋抵押贷款融资、住房公积金综合服务、置换贷款、交易赎楼、尾款垫付、不良资产处置等多项板块的融资服务。公司秉承着“诚信、专业、高效赢得服务”的经营理念，力求为每一位客户提供最好最优质的的服务，给更多的人带来财富。 </div>
</div>
</template>

<script>
export default {
  name: "company-profile"
}
</script>

<style scoped>
.image{
  width: 100%;
  height: 170px;
  margin-bottom: 34px;
}
.content-text{
  text-indent: 30px;
  padding: 0 15px;
  font-size: 14px;
  color: #333333;
  line-height: 24px;
  margin-bottom: 30px;
}

</style>